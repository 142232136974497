.fullWidthSplitSection {
  flex-direction: row;
  transition: all 0.5s ease-out;
}

.flipped {
  flex-direction: row-reverse;
}

.fullWidthSplitSection__image {
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  transition: all 0.5s ease-out;
}

.fullWidthSplitSection__content {
  text-align: left;
  width: 50%;
  /* padding: 125px; */
  /* padding: calc(50px + 0.4vw); */
  transition: all 0.5s ease-out;
}

/* @media only screen and (max-width: 768px) {
  .fullWidthSplitSection__content {
    padding: 30px;
  }
}

@media only screen and (max-width: 768px) {
    .fullWidthSplitSection__content {
      padding: 30px;
    }
  } */

/* 
@media only screen and (max-width: 1024px) {
  .fullWidthSplitSection__content {
    padding: 75px;
  }

.fullWidthSplitSection__image {
}
}

@media only screen and (max-width: 768px) {
  .fullWidthSplitSection__content {
    padding: 30px;
  }
} */

@media only screen and (max-width: 1024px) {
  .fullWidthSplitSection {
    flex-direction: column;
  }
  .fullWidthSplitSection__image {
    width: 100%;
    height: 400px;
  }
  .fullWidthSplitSection__content {
    width: 100%;
  }
}

@media only screen and (min-width: 1400px) {
  .fullWidthSplitSection__content {
    padding: 125px;
  }
}
